
.markdown {
  p { font-family: "Quattrocento"; }
  // p { font-family: "Libre Franklin"; }

  blockquote {
    margin-left: 10px;
    padding-left: 10px;
    border-left: 2px solid;
    border-left-color: rgba(200, 200, 200, 0.7)
  }
}
