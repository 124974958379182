g.hand-grid-border {
  &:hover {
    rect {
      stroke-width: 1px;
      stroke: hsla(0, 0%, 0%, 0.7);
    }
    text {
      font-weight: bold;
    }
  }

  rect.selected {
    stroke-width: 1.25px;
    stroke: hsla(0, 0%, 0%, 1);
  }
}
