.suit-spades {
  color: hsl(0, 0, 58);
}
.suit-spades-selected {
  color: hsl(0, 0, 63);
}
.suit-s-borderColor {
  border-color: hsl(0, 0, 58);
}
tspan.suit-spades {
  fill: hsl(0, 0, 0);
}

.suit-hearts {
  color: hsl(351, 100, 40);
}
.suit-hearts-selected {
  color: hsl(351, 100, 45);
}
.suit-h-borderColor {
  border-color: hsl(351, 100, 40);
}
tspan.suit-hearts {
  fill: hsl(0, 100, 40);
}

.suit-diamonds {
  color: hsl(204, 65, 71);
}
.suit-diamonds-selected {
  color: hsl(204, 65, 76);
}
.suit-d-borderColor {
  border-color: hsl(204, 65, 71);
}
tspan.suit-diamonds {
  fill: hsl(204, 100, 30);
}

.suit-clubs {
  color: hsl(154, 53, 55);
}
.suit-clubs-selected {
  color: hsl(154, 53, 60);
}
.suit-c-borderColor {
  border-color: hsl(154, 53, 55);
}
tspan.suit-clubs {
  fill: hsl(154, 100, 20);
}
