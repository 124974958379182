//
//  piosaas.scss
//
//  PioSaaS styles

// Fonts: Public Sans + Newsreader + Source Code Pro
// https://fonts.google.com/specimen/Public+Sans
// https://fonts.google.com/specimen/Newsreader
// https://fonts.google.com/specimen/Source+Code+Pro

@import url("https://rsms.me/inter/inter.css");

// Default variable overrides
$body-bg: rgb(52, 57, 62);
$body-color: #dcdcdc;

$btn-border-width: 0.25px;
$btn-close-color: $body-color;

$blue: #1f78b4;
// $indigo:  #6610f2;
// $purple:  #6f42c1;
// $pink:    #d63384;
// $red:     #dc3545;
$orange: #d95f02;
$yellow: #dead3b;
$green: #4a9c79;
// $teal:    #20c997;
// $cyan:    #0dcaf0;

$secondary: #636363;
$light: hsl(140, 10%, 94%);

$font-family-sans-serif: "Inter", -apple-system, system-ui, BlinkMacSystemFont,
  "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif !default;
$font-family-serif: Georgia, "Times New Roman", Times, serif !default;
$font-family-monospace: Menlo, Monaco, Consolas, "Liberation Mono",
  "Courier New", monospace !default;
$font-family-base: $font-family-sans-serif !default;

$breadcrumb-divider: quote(">");
// $breadcrumb-divider: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath d='M2.5 0L1 1.5 3.5 4 1 6.5 2.5 8l4-4-4-4z' fill='white'/%3E%3C/svg%3E");
$form-select-indicator-color: $body-color;

$form-select-indicator-color: $body-color;

$modal-content-bg: rgb(52, 57, 62);

@import "~bootstrap/scss/bootstrap.scss";
@import "footer.scss";
@import "markdown.scss";
@import "playing-cards.scss";
@import "viz.scss";

.breadcrumb {
  margin-bottom: 0px !important;
  font-size: smaller;
}

@include media-breakpoint-up(sm) {
  .breadcrumb {
    font-size: unset;
  }
}

h1 {
  @extend .pb-4;
}

h2 {
  @extend .py-3;
}

h3,
h4 {
  @extend .py-2;
}

// Pio-specific styles below

.card {
  background-color: hsl(0, 0%, 13%) !important;
}

a > .card {
  color: $body-color;
  &:hover {
    color: $primary;
    background-color: hsl(0, 0%, 5%) !important;
  }
}

div.project-card {
  &:hover {
    background-color: hsl(140, 5%, 89%) !important;
  }
}
div.selection-card-normal {
  border-radius: 8px;
  border-width: 0.15rem;
  border-style: solid;
  border-color: transparent;

  &:hover {
    cursor: pointer;
    background-color: hsl(210, 9%, 28%);
  }
  text-align: center;
}

div.selection-card-selected {
  cursor: default;
  text-align: center;
  border-radius: 8px;
  border-width: 0.15rem;
  border-style: solid;
}

.table.node-navigation {
  width: 0;
}
.table.node-navigation th,
tr {
  vertical-align: middle;
  padding-left: 0.7rem;
}

.no-wrap {
  white-space: nowrap;
}

.text-white {
  text-decoration-color: $body-color;
}
